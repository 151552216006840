import { editorialCategoryType } from '@/lib/sponsors';
import classNames from '@/utils/className';

type brandColor = editorialCategoryType;

interface WrapperProps {
  backgroundColor?: string;
  isOnHeader?: boolean;
  editorialCategory?: brandColor;
  children: JSX.Element[] | JSX.Element;
  paddingOnlyTop?: boolean;
}

export default function WrapperDestaque({
  children,
  backgroundColor = 'bg-mglGray50',
  isOnHeader,
  editorialCategory = undefined,
  paddingOnlyTop = false,
}: WrapperProps) {
  const colorsBrand: Record<brandColor, string> = {
    ctup: 'editorialCategory [&.editorialCategory]:bg-kabumBlue',
    eletro: 'editorialCategory [&.editorialCategory]:bg-eletroBlack',
    ctauto: 'editorialCategory [&.editorialCategory]:bg-ctautoRed',
  };

  return (
    <section
      className={classNames(
        'flex w-full flex-col flex-wrap content-center justify-center p-0 [&_.swiper-container]:w-full [&_.swiper-container]:py-0',
        isOnHeader && 'p-0 py-0',
        !isOnHeader && !paddingOnlyTop && 'p-0 py-6',
        backgroundColor,
        editorialCategory && colorsBrand[editorialCategory],
        paddingOnlyTop && 'p-0 py-0 pt-10',
        !paddingOnlyTop && '[&_.swiper-container]:px-6'
      )}>
      <div className="ml-0 w-full">{children}</div>
    </section>
  );
}
