import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  AdContainer: {
    noPadding: {
      true: 'noPadding [&.noPadding]:p-0',
    },
    billboard: {
      true: 'h-[250px] hidden tablet:flex',
    },
  },
  ContentListNews: {
    singleRow: {
      true: 'grid-rows-[auto]',
    },
  },
} as const;

export const AdContainer = ({ children, noPadding, billboard, ...rest }: ComponentPropsWithoutRef<'div'> & { noPadding?: boolean; billboard?: boolean }) => {
  return (
    <div
      className={classNames(
        'flex min-h-0 items-center justify-center bg-mglGray50 px-0 py-5',
        noPadding != undefined && variants.AdContainer.noPadding[noPadding.toString()],
        billboard != undefined && variants.AdContainer.billboard[billboard.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const ContentListNews = ({ children, singleRow, ...rest }: ComponentPropsWithoutRef<'div'> & { singleRow?: boolean }) => {
  return (
    <div
      className={classNames(
        'ml-0 grid grid-cols-1 grid-rows-2 gap-5 [grid-auto-columns:1fr] tablet:ml-auto tablet:grid-cols-2 desktop:grid-cols-3 [&_a]:mx-0 [&_a]:my-5 [&_a]:no-underline ',
        singleRow != undefined && variants.ContentListNews.singleRow[singleRow.toString()]
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const BillboardContainer = ({ children, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className="m-0 hidden tablet:mx-[-8.335%] tablet:my-0 tablet:flex" {...rest}>
      {children}
    </div>
  );
};
