import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
import WrapperDestaque from '@components/Layout/WrapperDestaque';
import { Vitrine } from '@/shared/types/home.types';
import Head from 'next/head';
import { getCurrentHub } from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import FeaturedNewsTitle from './NewsContent';
import classNames from '@/utils/className';
import { editorialCategoryType } from '@/lib/sponsors';

const SponsorBanner = dynamic(() => import('@/components/Layout/EditorialCategorySponsorBanner'));

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface NewsPropData {
  featuredNewsData: Vitrine[];
  editorialCategory?: editorialCategoryType;
}

const pagination = {
  clickable: true,
};

const navigation = {
  nextEl: '#destaque-container-area__next',
  prevEl: '#destaque-container-area__prev',
};

const variants = {
  ContainerDestaque: {
    editorialCategory: {
      ctup: 'editorialCategory [&.editorialCategory]:bg-kabumBlue [&_.swiper-button]:[&.editorialCategory]:bg-kabumOrange [&_.swiper-pagination-bullet]:[&.editorialCategory]:bg-mglGray50 [&_span.swiper-pagination-bullet-active]:[&.editorialCategory]:bg-kabumOrange',
      eletro:
        'editorialCategory [&.editorialCategory]:bg-eletroBlack [&_.swiper-button]:[&.editorialCategory]:bg-eletroGreen [&_.swiper-pagination-bullet]:[&.editorialCategory]:bg-mglGray50 [&_span.swiper-pagination-bullet-active]:[&.editorialCategory]:bg-eletroGreen',
      ctauto:
        'editorialCategory [&.editorialCategory]:bg-ctautoRed [&_.swiper-button]:[&.editorialCategory]:bg-mglGray50 [&_.swiper-button]:text-ctautoBlack [&_.swiper-pagination-bullet]:[&.editorialCategory]:bg-mglGray50 [&_span.swiper-pagination-bullet-active]:[&.editorialCategory]:bg-ctautoBlack',
    },
  },
} as const;

export default function FeaturedNews({ featuredNewsData, editorialCategory = undefined }: NewsPropData) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const featuredNewsSpan = currentSpan && currentSpan.startChild({ op: 'featuredNews' });

  if (featuredNewsData && featuredNewsData?.length > 3) {
    return (
      <WrapperDestaque editorialCategory={editorialCategory} backgroundColor={'bg-mglGray50'}>
        {featuredNewsData?.[0]?.imagem?.url && (
          <Head>
            <link rel="preload" as="image" href={featuredNewsData?.[0].imagem.url} />
          </Head>
        )}
        {editorialCategory && <SponsorBanner variant={editorialCategory} />}
        <div
          className={classNames(
            'relative block bg-mglGray50 [&_#destaque-container-area__next]:flex [&_#destaque-container-area__next]:h-auto [&_#destaque-container-area__next]:cursor-pointer [&_#destaque-container-area__next]:items-center [&_#destaque-container-area__prev]:flex [&_#destaque-container-area__prev]:h-auto [&_#destaque-container-area__prev]:cursor-pointer [&_#destaque-container-area__prev]:items-center [&_.container-swiper--next]:right-6 [&_.container-swiper--prev]:left-6 [&_.container-swiper]:z-[5] [&_.container-swiper]:opacity-100 [&_.container-swiper]:transition-opacity [&_.container-swiper]:duration-300 [&_.container-swiper]:ease-ease [&_.swiper-button]:hidden [&_.swiper-button]:desktop:flex [&_.swiper-pagination-bullet]:h-2 [&_.swiper-pagination-bullet]:w-2 [&_.swiper-pagination-bullet]:rounded-2xl [&_.swiper-pagination-bullet]:bg-black/20 [&_.swiper-pagination-bullet]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s] [&_.swiper-pagination]:bottom-0 [&_.swiper-pagination]:pr-[8.333%] [&_.swiper-pagination]:text-right [&_.swiper-slide]:tablet:w-[30%] first-of-type:[&_.swiper-slide]:tablet:w-[60%] [&:not(&:first-child)]:[&_.swiper-slide]:pl-2 [&:not(&:last-child)]:[&_.swiper-slide]:pr-2 [&_span.swiper-pagination-bullet-active]:w-[26px] [&_span.swiper-pagination-bullet-active]:bg-mglBlue400 [&_span.swiper-pagination-bullet-active]:[transition:background-color_0.3s_0s,_width_0.2s_0.3s]',
            editorialCategory != null && variants.ContainerDestaque.editorialCategory[editorialCategory]
          )}>
          <Swiper
            centeredSlides
            centeredSlidesBounds
            grabCursor
            pagination={pagination}
            navigation={navigation}
            preventClicks={false}
            preventClicksPropagation={false}
            speed={1000}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                initialSlide: 2,
                slidesPerView: 'auto',
                slidesPerGroup: 3,
              },
            }}>
            {featuredNewsData?.map((newData, index) => (
              <SwiperSlide key={newData.url}>
                <article className="relative h-[480px] w-full overflow-hidden rounded-lg">
                  <FeaturedNewsTitle
                    featured={index === 0}
                    eye={newData.categoria}
                    title={newData.titulo}
                    url={newData.url}
                    imageURL={newData.imagem.url}
                    blurhash={newData.imagem.blurhash}
                  />
                </article>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="container-swiper container-swiper--prev">
            <div id="destaque-container-area__prev">
              <div id="destaque-swiper-button__prev" className="swiper-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  width="1.5em"
                  height="1.5em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                  style={{
                    verticalAlign: '-0.125em',
                    transform: 'rotate(180deg)',
                  }}>
                  <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
                </svg>
              </div>
            </div>
          </div>
          <div className="container-swiper container-swiper--next">
            <div id="destaque-container-area__next">
              <div id="destaque-swiper-button__next" className="swiper-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  width="1.5em"
                  height="1.5em"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                  style={{
                    verticalAlign: '-0.125em',
                    transform: 'rotate(360deg)',
                  }}>
                  <path d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6l-1.41-1.42z" fill="currentColor" />
                </svg>
              </div>
            </div>
          </div>
          {featuredNewsSpan && featuredNewsSpan.finish()}
        </div>
      </WrapperDestaque>
    );
  }
  return null;
}
